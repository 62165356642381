.root {
  position: relative;
  width: 20.75rem;
  margin-left: 1.25rem;
}

.input {
  background-color: transparent;
  color: #353131;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  width: 100%;
  height: 3.125rem;
  line-height: 1.75rem;
  padding: 0 1.5rem;
  letter-spacing: 0.02rem;
  border: 0.063rem solid #d3d5d7;
  border-radius: 6.25rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    border-color: darken(#d3d5d7, 8%);
  }

  &:focus {
    border-color: #ef4c5e;
    outline: none;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  width: 2.625rem;
  height: 2.625rem;
  padding: 0;
}
