.root {
  background-color: transparent;
  color: #353131;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  width: 100%;
  height: 2.625rem;
  line-height: 1.75rem;
  padding: 0 1.75rem;
  letter-spacing: 0.02rem;
  border: 0.063rem solid #d3d5d7;
  border-radius: 6.25rem;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;

  &:focus {
    border-color: #ef4c5e;
    outline: none;
  }

  &[disabled] {
    background-color: lighten(#d3d5d7, 12%);
    opacity: 0.8;
    cursor: not-allowed;
  }
}
