.date {
  border: 1px solid #ebebeb;
  border-radius: 100px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 8fr 2fr;
  &__picker {
    padding: 16px 0px 16px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-right: 1px solid #ebebeb;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  * {
    font-weight: 400;
    font-size: 14px;
    color: #353131;
  }
}
