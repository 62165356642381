.date{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;
    &__box{
        display: flex;
        flex-direction: column;
        margin-left: 12px;
    }
    &__type{
        font-weight: 500;
        font-size: 14px;
        color: #353131;
    }
    &__numbers{
        font-weight: 400;
        font-size: 14px;
        color: #5B5A5A;
        margin-top: 8px;
    }
    &__icon{
        margin-top: 4px;
    }
}
