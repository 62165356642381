.job {
  background: #ffffff;
  border: 1px solid #ebebeb;
  padding: 24px;
  flex: 0 0 45%;
  border-radius: 8px;
  box-sizing: border-box;
  &__applied {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
  &__appliedto {
    font-weight: 400;
    font-size: 12px;
    color: #5b5a5a;
  }
  &__name {
    font-weight: 500;
    font-size: 14px;
    color: #353131;
    margin-left: 4px;
  }
  &__file {
    margin-top: 28px;
  }
  &__date {
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
    gap: 50px;
  }
  &__conversation {
    display: flex;
    justify-content: flex-end;
    margin-top: 26px;
    &__inner {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
      span,
      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #ef4c5e;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;
        margin-left: 14px;
      }
    }
  }
  &__apply {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    button {
      width: 170px;
    }
  }
  &__location {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 28px;
    img {
      width: 20px;
      height: 20px;
      margin-top: 3px;
    }
    &__info {
      display: flex;
      flex-direction: column;
      margin-left: 12px;
      &__title {
        font-size: 14px;
        font-weight: 500;
        color: #353131;
      }
      &__address {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        color: #5b5a5a;
      }
    }
  }
  &__edit {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    button {
      height: 36px;
      //width: 66px;
      text-align: center;
      line-height: 36px;
      background: #f5f5f5;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: darken(#f5f5f5, 3%);
      }

      &:focus {
        background-color: darken(#f5f5f5, 5%);
      }

      &.delete {
        background-color: #ef4c5e;
        color: white;

        &:hover {
          background-color: darken(#ef4c5e, 3%);
        }

        &:focus {
          background-color: darken(#ef4c5e, 5%);
        }
      }
    }
  }
  @media (max-width: 1160px) {
    flex: 0 0 100%;
  }
}
