.root {
  position: relative;
  button {
    height: 28px;
  }
}

.item {
  position: absolute;
  top: 3.375rem;
  transform: translateX(-50%);
  min-width: 9.25rem;
  z-index: 901;

  &.bottom {
    top: inherit;
    bottom: 48px;

    .body {
      &::before {
        top: inherit;
        bottom: -10px;
        transform: rotate(180deg);
      }
    }
  }
}

.body {
  width: 100%;
  background-color: white;
  border-radius: 0.5rem;

  &::before {
    content: url('data:image/svg+xml;utf8,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5999 12.5417L11.1666 7.10833C10.5249 6.46666 9.4749 6.46666 8.83324 7.10833L3.3999 12.5417" fill="white"/><path d="M16.5999 12.5417L11.1666 7.10833C10.5249 6.46666 9.4749 6.46666 8.83324 7.10833L3.3999 12.5417" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    display: block;
    position: absolute;
    top: -0.75rem;
    left: 85%;
    transform: translateX(-50%);
    width: 0.825rem;
    height: 0.369375rem;
  }
}
