.container {
  margin-block: 72px;
  @media (min-width: 768px) {
    margin-inline: 72px;
    margin-block: 152px;
  }
}
.header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  @media (min-width: 768px) {
    font-size: 48px;
    line-height: 48px;
  }

  color: #ef4c5e;
}

.subContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  @media (min-width: 768px) {
    column-gap: 136px;
    margin-top: 72px;
  }
}

.card {
  @media (min-width: 768px) {
    width: 196px;
  }
}

.cardHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #353131;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #5b5a5a;
  @media (min-width: 768px) {
    font-size: 14px;
    line-height: 22px;
  }
}
