body {
  background-color: #fafafa;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding-top: 5rem;
}

.pretty.p-fill .state {
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 0.02rem;
  color: #353131;
}

.pretty.p-fill .state svg {
  color: white;
}

.pretty.p-fill .state label {
  text-indent: 1.875rem;
}

.pretty.p-fill input:checked ~ .state label:after {
  background-color: #ef4c5e;
}

.pretty.p-fill svg {
  margin-left: 0.15625rem !important;
  margin-top: 0.15625rem !important;
}

.szh-accordion__item-content {
  transition: height 0.2s ease-in-out;
}

.szh-accordion__item-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  font-weight: 600;
  font-size: 1rem;
  width: 100%;
  height: 5rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
  color: #353131;
  border: none;
  border-bottom: 0.0625rem solid #ebebeb;
  padding: 0 0.75rem;
}

.szh-accordion__item-btn:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}

.szh-accordion__item:last-child .szh-accordion__item-btn,
.szh-accordion__item:last-child .szh-accordion__item-panel {
  border-color: transparent;
}

.szh-accordion__item--expanded .szh-accordion__item-btn {
  border-color: transparent;
}

.szh-accordion__item--expanded .szh-accordion__item-btn:hover {
  background-color: transparent;
}

.szh-accordion__item-panel {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  color: #5b5a5a;
  padding: 0 0.75rem 1.5rem;
  border-bottom: 0.0625rem solid #ebebeb;
}

.p-y-middle {
  padding: 1.5rem 0;
}

.h-fit {
  min-height: calc(100vh - 181px);
}

.error-default {
  color: #ef4c5e;
  font-size: 14px;
}

@media(max-width: 768px){
  body {
    padding-top: 52px;
  }
}
