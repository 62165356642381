.image {
  min-width: 172px;
  height: 152px;
  background: #fafafa;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover{
    background: darken(#fafafa,3%);
  }
  &__inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
    img {
      margin-left: auto;
      margin-right: auto;
    }
    span {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: #353131;
    }
  }
}
