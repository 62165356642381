.root {
  padding: 52px 0 92px;
  display: grid;
}

.container {
  width: 100%;
  @media (max-width: 768px) {
    margin: 0;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
}

.sidebar {
  width: 19.125rem;
  background-color: white;
  border: 1px solid #ebebeb;
  padding: 1.5rem;
  box-shadow: 0 0 1.875rem 0.0625rem rgba(202, 196, 196, 0.2);
  border-radius: 0.625rem;

  .header {
    width: 12.25rem;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    border-bottom: 0.0625rem solid #ebebeb;
  }
  // display: flex;
  // gap: 1.5rem;

  .items {
    padding-top: 3.1875rem;

    .title {
      font-weight: 500;
      font-size: 0.875rem;
      letter-spacing: 0.02rem;
      color: #8b8a8a;
      padding-bottom: 1.25rem;
    }

    .options {
      display: flex;
      flex-direction: column;
      gap: 0.875rem;
    }
  }
}

.body {
  width: 100%;

  .items {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1.5rem;
    padding-top: 3.25rem;
    @media (max-width: 1550px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (max-width: 992px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    @media (max-width: 500px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
