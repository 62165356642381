@import "styles/config/config.scss";

.container {
  position: relative;
}
.backgroundImage {
  width: 100%;
  height: auto;
  max-height: 643px;
  object-fit: cover;
}
.center {
  @include md-up {
    display: flex;
    justify-content: center;
  }
}

.contentContainer {
  position: relative;
  margin-top: 32px;
  @include md-up {
    position: absolute;
    margin-top: 0px;
    top: 194px;
    width: 966px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  /* Text Color */

  color: #353131;

  @include md-up {
    font-size: 52px;
    line-height: 72px;
    /* or 138% */

    text-align: center;

    /* White */

    color: #ffffff;
  }

  margin-block: 32px;
  max-width: 813px;
}

.redButton {
  min-width: fit-content;
  padding: 8px 30px;
  height: 52px;
  background: #353131;
  border-radius: 100px;
  color: #fff;
  background-color: #ef4c5e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;

  color: #ffffff;
}
