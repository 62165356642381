.file{
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    position: relative;
    &__pdf{
        padding: 21px 12px 21px 12px;
        background: #EF4C5E;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            font-weight: 500;
            font-size: 12px;
            color: #FFFFFF;
        }
    }
    &__info{
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__name{
        font-weight: 500;
        font-size: 12px;
        color: #353131;
        margin-bottom: 3px;
    }
    &__date{
        font-weight: 400;
        font-size: 10px;
        color: #AFACAC;
    }
    &__icon{
        height: 18px;
        width: 18px;
        align-self: end;
        padding: 5px;
        cursor: pointer;
    }
    &__cancel{
        position: absolute;
        top: -10px;
        right: -10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: none;
        &--active{
            display: block;
        }
    }
}
