.cost {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 100px;
  border: 1px solid #ebebeb;
  background: #fff;
  width: 100%;
  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 100px;
    border: 1px solid #ebebeb;
    padding: 16px 20px 16px 20px;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    span {
      margin-left: 12px;
      color: #353131;
      font-weight: 400;
      font-size: 14px;
    }
  }
  &__info {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 10px;
    input {
      border: none;
      outline: none;
      margin: 0px !important;
      width: 55px !important;
    }
    span {
      margin-right: 12px;
      color: #353131;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
