.invoice {
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  padding: 24px;
  flex: 0 0 29%;
  box-sizing: border-box;
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
  &__name {
    color: #5b5a5a;
    font-size: 12px;
    font-weight: 400;
  }
  &__value {
    color: #353131;
    font-weight: 500;
    font-size: 14px;
    margin-left: 8px;
  }
  &__button {
    button {
      min-width: 74px;
      height: 28px;
      font-weight: 600;
      font-size: 14px;
      padding: 0 16px;
    }
  }
  &__file {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &__download {
    font-weight: 500;
    font-size: 14px;
    color: #353131;
    text-decoration: underline;
    cursor: pointer;
  }
  @media (max-width: 1160px) {
    flex: 0 0 100%;
  }
}
