@import "styles/config/config.scss";

.accordion {
  width: 100%;

  @include md-up {
    width: 660px;
    margin: auto;
  }
  button {
    padding: 0;

    @include md-up {
      padding: 0 0.75rem;
      margin: auto;
    }
  }
  div {
    div {
      padding-inline: 0;
    }
  }
}

.root {
  background-color: white;
  padding: 9.5rem 0 0;
  @media (max-width: 768px) {
    padding: 72px 0 0;
  }
  padding: 4.5rem 0 0;
  margin-bottom: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include md-up {
    padding: 11rem 0 0;
  }
}

.container {
  @media (min-width: 48rem) {
    width: 42rem;
    display: flex;
    flex-direction: column;
  }
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0px 20px;
    box-sizing: border-box;
  }
}

.sup {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;

  /* Text Color */

  color: #353131;
  text-align: center;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 14px;
  }
  margin-bottom: 12px;
  @include md-up {
    color: #000000;
    text-align: center;
    margin-bottom: 14;
  }
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  /* Text Color */

  color: #353131;
  text-align: center;
  padding-top: 0.375rem;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    text-align: left;
    font-size: 24px;
    padding-bottom: 0px;
  }
  @include md-up {
    font-weight: 500;
    font-size: 32px;
    line-height: 52px;
    /* identical to box height, or 162% */

    letter-spacing: 0.02em;

    align-self: center;

    color: #000000;
    text-align: center;

    margin-bottom: 4.25rem;
    margin-top: 14px;
  }
}

.text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  letter-spacing: 0.02em;

  /* Icon Color */

  color: #353131;

  text-align: center;

  @include md-up {
    font-size: 16px;
    line-height: 32px;
  }
  margin-top: 28px;
  @media (max-width: 768px) {
    font-size: 12px;
    padding-top: 1.25rem;
  }
}

.button {
  width: 100%;
  padding: 8px 24px;
  margin-top: 0.75rem;
}

.link {
  max-width: 381px;
  width: 100%;
  align-self: center;

  @media (max-width: 768px) {

  }
}

.flex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: start;
  // margin-left: -12px !important;
}

.faqHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  letter-spacing: 0.02em;

  /* Icon Color */

  color: #353131;
  // max-width: 80%;
}

.content {
  margin: 0;
}
