.root {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.25rem;
  button{
    font-size: 12px;
  }
  @media(max-width: 768px){
    order: 2;
  }

  @media (max-widh: 48rem) {
    order: 1;
  }
}
