.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.125rem;
  height: 3.125rem;
  margin-left: 3.25rem;
  padding: 0;

  &.business {
    color: white;

    &:hover,
    &:focus{
      background-color: rgba(0, 0, 0, .12);
    }
  }

  @media (max-width: 992px) {
    margin-left: 1.25rem;
  }
  @media (max-width: 768px) {
    margin-left: 15px;
  }
}
