.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  color: #02A985;
  padding: 4rem 0;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.25rem;
  height: 3.25rem;
  background-color: #BDFEF0;
  color: #02A985;
  margin-bottom: 1rem;
  border-radius: 50%;
}
