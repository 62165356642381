.root {
  border: 0.0625rem solid #ebebeb;
  box-shadow: 0px 0px 30px 1px rgba(202, 196, 196, 0.2);
  border-radius: 0.625rem;
  margin: 1em 0;
  .body {
    padding: 1.75rem 1.25rem 0.875rem;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  height: 1.625rem;

  .save {
    color: #ef4c5e;
    height: 1.5625rem;
    padding: 0;

    &:hover {
      color: darken(#ef4c5e, 20%);
      background-color: transparent;
    }
  }
}

.logo {
  img {
    border-radius: 50%;
    object-fit: cover;
    border: 0.0625rem solid #ebebeb;
  }
}

.title {
  font-weight: 600;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
  color: #353131;
  padding-top: 0.875rem;
}

.subtitle {
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 1rem;
  letter-spacing: 0.02rem;
  color: #ef4c5e;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 1rem;

  .item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #353131;

    span {
      color: #ef4c5e;
    }

    small {
      font-weight: 400;
    }
  }
}

.cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0.0625rem solid #ebebeb;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #5b5a5a;
  margin-top: 1rem;
  padding: 1rem 0 0;

  .value {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: 0.02rem;
    color: #ef4c5e;

    small {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.75rem;
      color: #353131;
    }
  }

  .date {
  }
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  padding-top: 3rem;

  .button {
  }
}
