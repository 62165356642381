.root {
  background-color: #353131;
  height: 3.25rem;
  @media(max-width: 768px){
    height: auto;
    padding: 20px 0px 110px 0px;
  }
  @media (min-width: 48rem) {
    height: 3.25rem;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-block: 20px;
  @media (min-width: 48rem) {
    gap: 0px;
    padding-block: 0px;
    flex-direction: row;
    justify-content: space-between;
  }
  height: 100%;
  @media(max-width: 992px){
    margin: 0 20px;
  }
  @media(max-width: 768px){
    flex-direction: column;
    gap: 25px;
  }
}
