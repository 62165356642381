.container {
  position: relative;
}
.backgroundImage {
  width: 100%;
  height: auto;
  max-height: 643px;
  object-fit: cover;
}
.contentContainer {
  position: relative;
  margin-top: 32px;
  @media (min-width: 990px) {
    position: absolute;
    margin-top: 0px;
    top: 136px;
  }
}
.header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #353131;
  @media (min-width: 990px) {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 52px;
    line-height: 72px;
    color: #000000;
  }
}

.paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #353131;
  @media (min-width: 990px) {
    font-size: 16px;
    line-height: 32px;
    max-width: 584px;
    color: #000;
  }
}

.buttonContainer {
  display: flex;
  column-gap: 20px;
  margin-block: 32px;
  @media (min-width: 990px) {
    margin-top: 48px;
  }
}

.darkButton {
  padding: 8px 24px;
  //width: 142.8px;
  height: 42px;
  background: #353131;
  border-radius: 100px;
  color: #fff;
  background-color: #353131;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.redButton {
  padding: 8px 24px;
  //width: 142.8px;
  height: 42px;
  background: #353131;
  border-radius: 100px;
  color: #fff;
  background-color: #ef4c5e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;

  color: #ffffff;
}
