.root {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #353131;
  padding: 0 1rem;
  gap: 0.5rem;

  &.dark {
    color: white;

    &:hover {
      background-color: darken(#353131, 4%);
    }
  }

  &:focus {
    background-color: transparent;
  }
}

.language{
  @media(max-width: 768px){
    display: none;
  }
}

.link {
  text-decoration: none;

  .dark {
    color: white;

    &:hover {
      background-color: darken(#353131, 4%);
    }
  }
}

.dropdown {
  width: 187px;

  .list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 12px 0;

    li {
      display: flex;
      align-items: center;
      height: 32px;
      gap: 0.5rem;
      font-size: 0.875rem;
      color: #353131;
      padding: 0 1.5rem;

      strong {
        font-weight: 600;
      }

      a {
        text-decoration: none;
        color: #353131;
        display: flex;
        justify-content: flex-start;
        gap: 0.5rem;
      }

      &:hover {
        background-color: lighten(#ef4c5e, 35%);
        cursor: pointer;
      }
    }
  }
}