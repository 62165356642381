.root {
}

.item {
  position: fixed;
  top: 0;
  left: 0;
  width: 283px;
  height: 100%;
  background-color: white;
  padding: 0 0.875rem;
  box-sizing: border-box;
  z-index: 901;
}

.header {
  display: flex;
  align-items: center;
  height: 4.625rem;
  border-bottom: 0.0625rem solid #ebebeb;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #39375b;
}

.body {
  padding: 1rem 0 0;
}

.button {
  font-weight: 400;
  padding: 0;

  span {
    font-weight: 600;
  }

  // &:hover,
  // &:focus {
  //   background-color: transparent;
  // }
}

.button {
  width: 100%;
  background-color: #ef4c5e;
  padding: 8px 20px;
  gap: 8px;
  margin-block: 3.25rem;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow-y: hidden;
  // height: calc(100% - 300px);
}

.linkContainer {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.link {
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.04em;

  /* Icon Color */

  color: #353131;
}

.margin {
  margin: 0 !important;
}

.borderBottom{
  padding-bottom: 32px;
  border-bottom: 1px solid #EBEBEB;
}
