@import "styles/config/config.scss";

.header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 114% */

  letter-spacing: 0.02em;

  /* Text Color */

  color: #353131;

  margin-bottom: 12px;
  margin-top: 2rem;
  margin-inline: 20px;

  @include md-up {
    display: none;
  }
}

.profile {
  display: flex;
  gap: 30px;
  padding: 72px;
  align-items: flex-start;
  &__card {
    width: 100%;
    padding: 52px 32px 52px 52px;
    background: #fffdfd;
    border-radius: 8px;
    border: 1px solid #ebebeb;
    &--fixed-height {
      height: 476px;
      box-sizing: border-box;
    }
    &__unset {
      padding: 0px;
      background: none;
      border-radius: 0px;
      border: none;
      @media (max-width: 992px) {
        padding: 0px !important;
      }
    }
    @media (max-width: 992px) {
      padding: 20px;
      border: none;
      border-radius: none;
    }
  }
  @media (max-width: 992px) {
    // padding: 30px;
    // gap: 15px;
    padding: 0px;
  }
}

body {
  background: #ffffff;
}

.tag {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.vorteilContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-wrap: wrap;
  max-width: 70%;

  @include md-up {
    flex-direction: row;
  }
}

.root {
  @include md-up {
    padding-inline: 2rem !important;
  }
}
