@import "styles/config/config.scss";

.conversation {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 100% 100%;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  @media (max-width: 992px) {
    grid-template-columns: 100%;
    border: none;
  }
  &__left {
    border-right: 1px solid #ebebeb;
  }
  &__messages {
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow: auto;
    box-sizing: border-box;
    max-height: 400px;
  }
  &__info {
    padding: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 992px) {
      display: none;
    }
  }
  &__avatar {
    margin-top: 8px;
  }
  &__details {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    img {
      cursor: pointer;
    }
  }
  &__jobs {
    margin-top: 50px;
    &__title {
      color: #353131;
      font-weight: 500;
      font-size: 12px;
    }
    &__inner {
      display: flex;
      flex-direction: column;
      overflow: auto;
      max-height: 350px;
      .job {
        width: 100%;
        background: #f5f5f5;
        padding: 10px 24px 10px 24px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: 14px;
        &__title {
          font-weight: 600;
          font-size: 14px;
          color: #353131;
        }
        &__details {
          font-weight: 400;
          font-size: 12px;
          color: #5b5a5a;
          margin-top: 5px;
        }
      }
    }
  }
  &__hotel {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &__name {
      font-weight: 400;
      font-size: 14px;
      color: #353131;
      margin-left: 14px;
    }
  }
  &__end {
    width: 100%;
    display: flex;
    justify-content: center;
    a {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.02em;
      text-decoration-line: underline;
      color: #ef4c5e;
    }
  }
}

.message {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 14px 24px 14px;
  border-radius: 10px;
  background: #ffffff;
  width: 100%;
  margin-bottom: 40px;
  box-sizing: border-box;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  &--gray {
    background: #f5f5f5;
  }
  &__box {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
  }
  &__name {
    font-weight: 700;
    font-size: 14px;
    color: #353131;
    &__desktop {
      @media (max-width: 992px) {
        display: none;
      }
    }
  }
  &__text {
    font-weight: 400;
    font-size: 14px;
    margin-top: 20px;
  }
  &__avatar {
    @media (max-width: 992px) {
      display: none;
    }
    &__mobile {
      display: none;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      &--left {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        span {
          margin-left: 8px;
        }
      }
      @media (max-width: 992px) {
        display: flex;
      }
    }
  }
}

.editor {
  height: 260px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 30px 1px rgba(89, 88, 88, 0.2);
  margin: 14px;
  &__top {
    padding: 21px 25px 19px 25px;
    border-bottom: 1px solid #ebebeb;
  }
  &__to {
    color: #353131;
    font-weight: 400;
    font-size: 12px;
  }
  &__name {
    font-weight: 500;
    font-size: 12px;
    margin-left: 8px;
  }
  &__bottom {
    padding: 20px;
  }
  &__textarea {
    width: 100%;
    height: 100px;
    resize: none;
    border: none;
    outline: none;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  &__tools {
    display: flex;
    justify-content: flex-start;
  }
  &__tool-item {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
  }
  &__send {
    display: flex;
    align-items: center;
  }
}

.disN {
  margin-top: 52px;
  @include md-up {
    display: none;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    color: #8B8A8A;
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;
    line-height: 1.5rem;
  }

  .link {
    color: #ef4c5e;
    padding: 0;

    &:hover,
    &:focus {
      background-color: transparent;
      text-decoration: underline;
    }
  }
}
