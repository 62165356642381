.root {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #353131;
  padding: 0 1rem;
  gap: 0.5rem;
  @media (max-width: 768px) {
    color: #fff;
  }
}

.root svg {
  color: #ef4c5e;
}

.body {
  width: 230px;
}

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 12px 0;

  li {
    display: flex;
    align-items: center;
    height: 32px;
    gap: 0.5rem;
    font-size: 0.875rem;
    padding: 0 1.5rem;

    strong {
      font-weight: 600;
    }

    &:hover {
      background-color: lighten(#ef4c5e, 35%);
      cursor: pointer;
    }
  }
}
