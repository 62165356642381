@import "styles/config/config.scss";

.flex {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @include md-up {
    flex-direction: row;
  }
}

.inputContainer {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @include md-up {
    position: relative;
    justify-content: space-between;
    flex-direction: row;
    background-color: #fff;
    border-radius: 100px;
    padding: 4px;
    align-items: center;
    overflow: hidden;
  }
}
.divider {
  @include md-up {
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    left: 352px;
    z-index: 5;
  }
}
.input {
  background: #ffffff;
  /* Light Inner Text */

  border: 1px solid #afacac;
  border-radius: 100px;
  width: 100%;
  @include md-up {
    border: none;
  }
  height: 52px;
  padding-inline: 2rem;
  padding-left: 3.5rem;
  outline: none;
}

.redButton {
  min-width: fit-content;
  padding: 8px 30px;
  height: 52px;
  background: #353131;
  border-radius: 100px;
  color: #fff;
  background-color: #ef4c5e;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px 0 8px;
  cursor: pointer;
}

.buttonLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.02em;

  color: #ffffff;
}

.inputIcon {
  position: absolute;
  left: 2rem;
  margin-right: 10px;
  z-index: 0;
}

.inputWrapper {
  @include md-up {
    width: 348px;
  }
  position: relative;
  display: flex;
  align-items: center;
}
