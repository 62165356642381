@import "styles/config/config.scss";

.root {
  background-color: #fffcfc;

  margin-top: 52px;
  @include md-up {
    margin-top: 0px;
  }
}

.container {
  position: relative;
  height: 100%;
  width: calc(100% - 4.5rem);
}
.innerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @include md-up {
    flex-direction: row;
  }
}
.content {
  margin-top: 3.25rem;
  @include md-up {
    width: 42.75rem;
    padding-top: 6.25rem;
  }
}

.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  /* or 142% */

  /* Icon Color */

  color: #353131;

  @include md-up {
    font-size: 3.25rem;
    line-height: 4.5rem;
  }
}

.description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  margin-top: 0.75rem;
  /* or 167% */

  /* Text Color */

  color: #353131;

  @include md-up {
    font-size: 1rem;
    line-height: 2rem;
    color: #5b5a5a;
    margin-top: 2rem;
  }
}

.actions {
  display: flex;
  margin-top: 3rem;
  gap: 16px;
  @include md-up {
    gap: 2rem;
  }
  button {
    @include sm-down {
      height: 40px;
      width: 130px;
      white-space: nowrap;
    }
  }
}

.button {
  width: 9.5rem;
  padding: 0;
}

.imageContainer {
  background-color: green;
}

.image {
  width: "100%";
  object-fit: contain;

  @include md-up {
    width: auto;
    align-self: flex-end;
  }
}
