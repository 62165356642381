.hotel {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 24px;
  flex: 0 0 45%;
  box-sizing: border-box;
  &__details {
    margin-top: 29px;
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 24px;
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      color: #8b8a8a;
      margin-left: 14px;
    }
  }
  &__edit {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    button {
      height: 36px;
      width: 66px;
      text-align: center;
      line-height: 36px;
      background: #f5f5f5;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: darken(#f5f5f5, 3%);
      }

      &:focus {
        background-color: darken(#f5f5f5, 5%);
      }

      &:last-child {
        background-color: #ec3045;
        color: white;

        &:hover {
          background-color: darken(#ec3045, 3%);
        }

        &:focus {
          background-color: darken(#ec3045, 5%);
        }
      }
    }
  }
  &__add {
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__icon {
      height: 52px;
      width: 52px;
      margin-left: auto;
      margin-right: auto;
    }
    &__box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &__text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 37px;
      span {
        margin-left: 14px;
        font-weight: 400;
        font-size: 20px;
        color: #353131;
      }
    }
  }
  @media (max-width: 1160px) {
    flex: 0 0 100%;
  }
}
