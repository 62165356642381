.root {
  padding: 9.5rem 0;
}

.container {
  @media (max-width: 768px) {
    margin: 0 1.5rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  padding-top: 2rem;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3.25rem;
  color: #353131;
  @media (max-width: 768px) {
    font-size: 18px;
  }

  .button {
    color: #ef4c5e;
    margin-left: 0.875rem;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: darken(#ef4c5e, 8%);
      text-decoration: underline;
    }
  }
}

.dis {
  display: none;
}
