.root {
  padding: 9.5rem 0;
  @media(max-width: 768px){
    padding: 102px 0px 72px 0px;
  }
}

.container {

}

.header {

}

.body {
  // display: grid;
  // grid-template-columns: repeat(5, minmax(0, 1fr));
  // gap: 1.5rem;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3.25rem;
  color: #353131;
}

.blur{
  position: absolute;
  right: 0px;
  height: 100%;
  width: 100px;
  background: white;
}
