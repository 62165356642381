.root {
}

.input {
  margin-bottom: 1.25rem;
}

.errored {
  input {
    border-color: #ef4c5e;
  }
}

.error {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #ef4c5e;
  padding: 0.5rem 0.625rem 0;
}

.flex {
  display: flex;
  margin-top: -16px;
  margin-bottom: 16px;
}

.forgot {
  font-weight: 500;
  text-decoration: underline;
  padding: 0 0.25rem;
  margin-left: auto;

  &:hover,
  &:focus {
    background-color: transparent;
    text-decoration: none;
  }
}
