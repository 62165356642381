.slick-list {
  margin: 0 -12px;
  position: relative;
}
.slick-slide > div {
  margin-right: 12px;
  @media (max-width: 768px) {
    padding: 0 0px;
  }
  &>div{
    box-shadow: unset;
  }
}