// greater then 961px
@mixin xs-up {
  @media screen and (min-width: 601px) {
    @content;
  }
}
// less then 960px
@mixin sm-down {
  @media screen and (max-width: 768px) {
    @content;
  }
}

// greater then 961px
@mixin md-up {
  @media screen and (min-width: 769px) {
    @content;
  }
}

// greater then 1281pxpx
@mixin lg-up {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

// greater then 1536pxpx
@mixin xl-up {
  @media screen and (min-width: 1536px) {
    @content;
  }
}

@mixin full-hd {
  @media screen and (min-width: 1920px) {
    @content;
  }
}
