.user{
    &__title{
        color: #353131;
        font-weight: 600;
        font-size: 28px;
        line-height: 32px;
    }
    &__info{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 32px;
    }
    &__input__row{
        display: flex;
        gap: 73px;
        margin-top: 52px;
        @media(max-width: 992px){
            flex-direction: column;
            margin-top: 20px;
        }
        &-2nd{
            gap: 144px;
        }
        &__address{
            display: flex;
            margin-top: 52px;
            justify-content: space-between;
            align-items: flex-end;
            @media(max-width: 992px){
                flex-direction: column;
                margin-top: 20px;
            }
            button{
                width: 250px;
                height: 50px;
                @media(max-width: 1200px){
                    width: 100px;
                }
                @media(max-width: 992px){
                    width: 100%;
                    margin-top: 40px;
                }
            }
        }
        @media(max-width: 1200px){
            gap: 20px;
        }
    }
    &__input{
        flex-grow: 1;
        width: calc(100% / 3);
        display: flex;
        flex-direction: column;
        &--left{
            width: 60%;
            &-2nd{
                width: 45%;
            }
        }
        &--right{
            width: 35%;
            &-2nd{
                width: 30%;
            }
        }
        &__address{
            width: 50%;
            flex-grow: initial;
        }
        label{
            color: #353131;
            font-weight: 500;
            font-size: 16px;
        }
        input{
            width: 100%;
            margin-top: 12px;
            box-sizing: border-box;
            background: white;
        }
        @media(max-width: 992px){
            width: 100%;
        }
    }
}

.error {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #EF4C5E;
    padding: 3rem 0 0;
    text-align: right;
}

.success {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #22C0A0;
    padding: 3rem 0 0;
    text-align: right;
}
