.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.6875rem;
  background-color: white;
  border: 0.0625rem solid #ebebeb;
  border-radius: 6.25rem;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    border: none;
    background: none;
    border-radius: unset;
    gap: 12px;
  }
  button {
    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.keyword,
.location {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  width: 65%;
  height: 100%;
  padding-left: 2rem;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    border: 1px solid #afacac;
    border-radius: 100px;
    height: 52px;
    box-sizing: border-box;
  }
}

.icon {
  color: #353131;

  svg {
    position: relative;
    top: 0.125rem;
  }
}

.input {
  background-color: transparent;
  color: #8b8a8a;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  width: 100%;
  height: 100%;
  line-height: 1.75rem;
  padding: 0;
  letter-spacing: 0.02rem;
  box-sizing: border-box;
  border: none;
  width: 100%;
  @media (max-width: 768px) {
    border: none;
  }

  &:focus {
    outline: none;
  }
}

.location {
  .input {
    border-right: none;
  }
}

.button {
  width: 12.625rem;
  margin-right: 0.25rem;
  padding: 0;
}
