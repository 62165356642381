.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  height: 5rem;
  border-bottom: 0.063rem solid #ebebeb;
  border-bottom: 0.063rem solid #ebebeb;
  z-index: 2;
  @media(max-width: 768px){
    height: 52px;
  }
}

.dark {
  background-color: #353131;
}

.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  @media (max-width: 992px) {
    margin: 0 20px;
  }
}
