.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  &__btn {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    border: 1px solid #353131;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: none;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
