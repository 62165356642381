@import "styles/config/config.scss";

.root {
  height: 14.4375rem;
  background-color: #fffcfc;
}


.container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @include md-up {
    margin-top: 0px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include md-up {
    flex-direction: row;
    gap: 1.5rem;
  }

  @include md-up {
    flex-direction: row;
  }

  .logo {
    img {
      border-radius: 50%;
      object-fit: cover;
      border: 0.0625rem solid #ebebeb;
    }
  }

  .info {
    text-align: center;
    @include md-up {
      text-align: start;
    }
    .hotel {
      margin: 0;

      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.02rem;
      color: #ef4c5e;
    }

    .position {
      font-weight: 600;
      font-size: 3.25rem;
      line-height: 4.5rem;
      letter-spacing: 0.02rem;
      color: #353131;
    }
  }
}

.saveButton {
  display: none;
  @include md-up {
    display: block;
  }
}
