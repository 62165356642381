@import "styles/config/config.scss";

.root {
  padding-block: 4.5rem !important;
  width: 100%;

  @include md-up {
    padding-block: 9.5rem !important;
  }
}

.container {
}

.header {
}

.body {
  @include md-up {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
  }
}

.title {
  font-weight: 700;
  font-size: 2rem;
  line-height: 3.25rem;
  color: #353131;
}

.content {
  width: 100%;

  @include md-up {
    width: 60.375rem;
  }
}

.content__items {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include md-up {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.search {
  margin-top: 31px;
  background-color: transparent;

  @include md-up {
    margin-top: 3.25rem;
    border: 1px solid;
    border-color: #afacac;
  }

  input {
    border-color: #afacac;
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  @media (max-width: 768px) {
    font-size: 14px;
    color: #353131;
    font-weight: 500;
  }
}
