.pricing {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  &__card {
    flex: 0 0 47%;
    background: #ffffff;
    box-shadow: 0px 0px 30px 1px rgba(89, 88, 88, 0.2);
    border-radius: 10px;
    padding: 32px 24px 32px 24px;
    box-sizing: border-box;
    &__inner {
      padding: 0px 10px 0px 10px;
    }
    &__item {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
    }
    &__title {
      color: #353131;
      font-weight: 600;
      font-size: 16px;
      text-transform: capitalize;
    }
    &__text {
      color: #5b5a5a;
      font-weight: 400;
      font-size: 12px;
      margin-top: 5px;
      text-transform: capitalize;
    }
    &__button{
        width: 100%;
        margin-top: 63px;
        button{
            width: 100%;
        }
    }
  }
}

.pricing-grid {
  display: grid;
}
