.root {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: white;
  padding: 0;
  gap: 0.5rem;
}

.root:hover {
  background-color: transparent;
}
