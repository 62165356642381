.select {
  width: 100%;
  height: 52px;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  padding: 16px 20px 16px 52px;
  background-repeat: no-repeat, no-repeat;
  background-size: 20px 20px, 10px 10px;
  background-position: 20px center, calc(100% - 20px) center;
  appearance: none;
}
