@import "styles/config/config.scss";

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $backdrop;
  z-index: 900;
}
