.root {
  background-color: #D1D1D1;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  height: 2.625rem;
  line-height: 1.75rem;
  padding: 0 1.75rem;
  letter-spacing: 0.02rem;
  border: none;
  border-radius: 6.25rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: darken(#D1D1D1, 3%);
    cursor: pointer;
  }

  &:focus {
    background-color: darken(#D1D1D1, 5%);
    outline: none;
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.primary {
  background-color: #EF4C5E;

  &:hover {
    background-color: darken(#EF4C5E, 6%);
  }

  &:focus {
    background-color: darken(#EF4C5E, 12%);
  }

  &.outline {
    color: #EF4C5E;
    border: 0.0625rem solid #EF4C5E;

    &:hover {
      color: white;
    }
  }
}

.dark {
  background-color: #353131;

  &:hover {
    background-color: darken(#353131, 6%);
  }

  &:focus {
    background-color: darken(#353131, 12%);
  }

  &.outline {
    color: #353131;
    border: 0.0625rem solid #353131;

    &:hover {
      color: white;
    }
  }
}

.light {
  background-color: #D3D5D7;

  &:hover {
    background-color: darken(#D3D5D7, 6%);
  }

  &:focus {
    background-color: darken(#D3D5D7, 12%);
  }

  &.outline {
    color: #353131;
    border: 0.0625rem solid #D3D5D7;

    &:hover {
      color: #353131;
    }
  }
}

.transparent {
  background-color: transparent;
  color: #353131;

  &:hover {
    background-color: lighten(#D1D1D1, 12%);
  }

  &:focus {
    background-color: lighten(#D1D1D1, 8%);
  }
}

.dark{
  background-color: #353131;

  &:hover {
    background-color: darken(#353131, 3%);
  }

  &:focus {
    background-color: darken(#353131, 5%);
  }
}

.success{
  background-color: #4EAF75;

  &:hover {
    background-color: darken(#4EAF75, 3%);
  }

  &:focus {
    background-color: darken(#4EAF75, 5%);
  }
}

.warning {
  background-color: #353131;

  &:hover {
    background-color: darken(#353131, 3%);
  }

  &:focus {
    background-color: darken(#353131, 5%);
  }
}

.lg {
  height: 3.25rem;
}

.block {
  width: 100%;
}

.outline {
  background-color: transparent;
}
