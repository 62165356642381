.jobs{
    display: flex;
    flex-wrap: wrap;
    gap: 52px;
    @media(max-width: 1205px){
        gap: 10px;
    }
}

.item {
    flex: 0 0 45%;
    text-decoration: none;
}
