.add {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 24px;
  flex: 0 0 45%;
  box-sizing: border-box;
  user-select: none;
  &:hover{
    background: darken(#f5f5f5,2%);
  }
  &__icon {
    height: 52px;
    width: 52px;
    margin-left: auto;
    margin-right: auto;
  }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 37px;
    span {
      margin-left: 14px;
      font-weight: 400;
      font-size: 20px;
      color: #353131;
    }
  }
}
