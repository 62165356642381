.root {
  display: flex;
  align-items: center;
  height: 100%;
}

.search{
  @media(max-width: 768px){
    display: none;
  }
}