.root {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  gap: 3.25rem;
  @media(max-width: 768px){
    order: 1;
  }
}

.item {
}

.link {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: white;
  text-decoration: none;
  @media(max-width: 768px){
    font-size: 14px;
  }
}

.link:hover {
  text-decoration: underline;
}
