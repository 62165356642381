.root {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 0;

    .title {
      font-weight: 600;
      font-size: 1.25rem;
      line-height: 2rem;
      letter-spacing: 0.02rem;
      color: #353131;
      padding-top: 0.5rem;
    }

    .subtitle {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.75rem;
      letter-spacing: 0.02rem;
      color: #8B8A8A;
      padding-top: 0.5rem;
    }

    .button {
      font-weight: 500;
      text-decoration: underline;
      padding: 0 0.25rem;

      &:hover,
      &:focus {
        background-color: transparent;
        text-decoration: none;
      }
    }

    .thumb {
      img {
        width: 3.25rem;
        height: 3.25rem;
        border-radius: 50%;
        object-fit: cover;
        border: 0.0625rem solid #EBEBEB;
      }
    }
  }

  .body {
    padding: 0 3.25rem;
  }

  input {
    height: 3.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.02rem;
    color: #8B8A8A;
  }

  label {
    display: block;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    letter-spacing: 0.02rem;
    color: #353131;
    padding-bottom: 0.5rem;
  }

  .divider {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 2rem 0;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 0.0625rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: #D3D5D7;
      z-index: 1;
    }

    .value {
      position: relative;
      background-color: white;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.02rem;
      color: #5B5A5A;
      padding: 0 0.75rem;
      z-index: 2;
    }
  }

  .social {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .button {
      font-weight: 500;
    }
  }

  .additional {
    display: flex;
    justify-content: center;
    gap: 1.75rem;
    padding-bottom: 2.125rem;

    .button {
      font-weight: 400;
      text-decoration-line: underline;
      height: auto;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.error {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #EF4C5E;
  padding: 0 0 0.75rem;
}
