.avatar{
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #EBEBEB;
    &__img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.lg{
    width: 90px;
    height: 90px;
}

.md{
    width: 52px;
    height: 52px;
}

.sm{
    width: 42px;
    height: 42px;
}

.centered{
    margin-left: auto;
    margin-right: auto;
}