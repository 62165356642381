.form {
  &__inner {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 155px;
  }
  &__title {
    color: #000000;
    font-weight: 600;
    font-size: 28px;
  }
  &__input {
    margin-top: 52px;
    font-size: 14px;
    label,
    &__title {
      color: #353131;
      font-weight: 500;
      font-size: 16px;
    }
    input {
      width: 100%;
      margin-top: 12px;
      box-sizing: border-box;
      background: white;
      margin-bottom: 8px;
    }
    select {
      margin-top: 12px;
      margin-bottom: 8px;
    }
    &__date {
      display: flex;
      flex-direction: column;
      gap: 12px;
      input {
        margin-top: 0px;
        width: auto;
        background: none;
        box-sizing: unset;
      }
    }
    &__cost {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
  &__mark {
    margin-top: 24px;
  }
  &__seniority {
    &__inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      padding: 8px 20px 8px 20px;
      border: 1px solid #ebebeb;
      border-radius: 100px;
      width: 100%;
      box-sizing: border-box;
    }
  }
  &__editor {
    margin-top: 15px;
    min-height: 350px;
  }
  &__uploader {
    margin-top: 80px;
    &__title {
      font-weight: 600;
      font-size: 16px;
      color: #353131;
    }
    &__inner{
        margin-top: 15px;
    }
  }
  &__end{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 52px;
  }
  &__checkboxes{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  &__publish{
    button{
        width: 100%;
    }
  }
}

.error {
  display: table;
  color: #EF4C5E;
  margin-top: 25px;
  margin-left: auto;
}
