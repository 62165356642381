@import "styles/config/config.scss";
.root {
  margin: 0 1.25rem;
  width: calc(100% - 2.5rem);
  @include md-up {
    width: auto;
    max-width: 1296px;
    margin: auto;
  }
}
