.root {
  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2.5rem;
    padding: 0;
    border: 0.0625rem solid #EF4C5E;
    border-radius: 6.25rem;

    &:hover {
      background-color: #EF4C5E;
      color: white;
      cursor: pointer;
    }
  }

  .card {
    width: auto;

    div:nth-child(2) {
      justify-content: center;
    }
  }
}

.input {
  display: none;
}
