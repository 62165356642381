.root {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  gap: 0.25rem;
  @media (max-width: 768px) {
    order: 3;
  }

  @media (max-widh: 48rem) {
    order: 2;
  }
}

.item {
  font-weight: 300;
  font-size: 0.75rem;
  color: #afacac;
}

.link {
  font-weight: 500;
  line-height: 1.125rem;
  letter-spacing: 0.02rem;
  color: #afacac;
  text-decoration: underline;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.link:hover {
  color: lighten(#afacac, 16%);
  text-decoration: none;
}
