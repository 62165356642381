.root {

}

.input {
  margin-bottom: 1.25rem;
}

.errored {
  input {
    border-color: #EF4C5E;
  }
}

.error {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: #EF4C5E;
  padding: 0.5rem 0.625rem 0;
}
