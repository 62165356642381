.nav {
  width: 100%;
  height: 60px;
  display: none;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
  background: #fffdfd;
  box-shadow: 0px 0px 30px 1px rgba(89, 88, 88, 0.2);
  position: fixed;
  bottom: 0px;
  z-index: 10000;
  @media (max-width: 768px) {
    display: flex;
  }
}
