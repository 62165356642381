.root {
  background-color: #ffffff;
  border-radius: 0.5rem;
}

.header {
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

.header {
  position: relative;
  padding-top: 2.25rem;
}

.title {
  color: #353131;
  font-style: normal;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2rem;
  letter-spacing: 0.02rem;
}

.subtitle {
  color: #353131;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.02rem;
  padding-top: 1.75rem;
}

.exit {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;

  @media(max-width: 768px){
    right: 8px;
    top: 30px
  }
}
