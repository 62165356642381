.settings{
    background: #353131;
    border-radius: 8px;
    padding: 32px;
    width: 300px;
    &__title{
        font-weight: 700;
        font-size: 14px;
        color: #FFFFFF;
    }
    &__list{
        padding: 0px;
        list-style: none;
    }
    &__item{
        border-bottom: 1px solid #5B5A5A;
        padding: 10px 0px 10px 0px;
        a{
            font-weight: 500;
            font-size: 14px;
            color: #D3D5D7;
            text-decoration: none;
        }
        .active{
            color: #FFFFFF;
            text-decoration: underline;
        }
    }
    @media(max-width: 992px){
        display: none;
    }
}