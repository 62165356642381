.root {
  background-color: #353131;
  padding: 0;
  padding-bottom: 2rem;

  @media (min-width: 48rem) {
    height: 32.4375rem;
    padding-bottom: 0;
    padding-inline: 100px;
  }
  @media(max-width: 768px){
    height: auto;
  }
}

.container {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 48rem) {
    grid-template-columns: calc(100% - 373px) 373px;
  }
  grid-template-columns: calc(100% - 373px) 373px;
  @media (max-width: 1200px) {
    margin: 0px 1.5rem;
  }
  @media(max-width: 768px){
    display: flex;
    flex-direction: column;
  }
}

.card {
  margin-top: 1.5rem;
  padding: 2rem 1.5rem;
  box-shadow: 0 0 1.875rem 0.0625rem rgba(89, 88, 88, 0.2);
  border-radius: 0.625rem;
  @media(max-width: 768px){
    margin-top: 0px;
  }
}

.left {
  max-width: 660px;

  .title {
    font-weight: 700;
    color: white;
    padding-top: 2rem;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 34px;
    @media (min-width: 48rem) {
      font-size: 3rem;
      line-height: 3rem;
      padding-top: 3.25rem;
    }
    font-size: 3rem;
    line-height: 3rem;
    color: white;
    padding-top: 3.25rem;
    @media (max-width: 992px) {
      font-size: 24px;
    }
  }

  .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding-bottom: 2rem;
    padding-top: 1.3rem;
    color: white;

    @media (min-width: 48rem) {
      font-size: 1rem;
      line-height: 2rem;
      padding-top: 1.5rem;
      padding-bottom: 5rem;
    }
    font-size: 1rem;
    line-height: 2rem;
    color: white;
    padding-top: 1.5rem;
    padding-bottom: 5rem;
    width: 80%;
    @media (max-width: 992px) and (min-width: 768px) {
      padding-bottom: 3rem;
      font-size: 14px;
      line-height: 20px;
    }

    @media(max-width: 768px){
      padding: 12px 0px 32px 0px;
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.right {
}

.tags {
  display: grid;
  grid-template-columns: 5fr 1fr;
  max-width: 750px;
  @media (max-width: 992px) and (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  @media(max-width: 768px){
    padding: 32px 0px 32px 0px;
    grid-template-columns: 5fr 2fr;
  }

  &__inner {
    display: grid;
    gap: 20px;
    grid-template-columns: 50% 50%;
    @media (max-width: 992px) and (min-width: 768px) {
      gap: 10px;
    }
    @media(max-width: 768px){
      display: flex;
      flex-direction: column;
    }
  }
  &__more {
    display: flex;
    align-items: flex-end;
    span {
      color: #ef4c5e;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__desktop{
    @media(max-width: 768px){
      display: none;
    }
  }
  &__mobile{
    display: none;
    @media(max-width: 768px){
      display: grid;
    }
  }
}

.tag {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: white;
  @media (max-width: 992px) {
    font-size: 14px;
  }
}

.button {
  margin-top: 3.25rem;
}

.timeline {
  .item {
    position: relative;
    height: 5rem;
    padding-left: 2.5rem;

    &:last-child {
      &::after {
        display: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.0625rem solid #afacac;
      border-radius: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 1.875rem;
      left: 0.8125rem;
      display: block;
      height: 2.4375rem;
      border-left: 0.0625rem dashed #d9d9d9;
    }

    .title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #353131;
      @media(max-width: 768px){
        font-size: 14px;
      }
    }

    .subtitle {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 0.75rem;
      letter-spacing: 0.02rem;
      color: #5b5a5a;
      padding-top: 0.1875rem;
      @media(max-width: 768px){
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
}
