@import "styles/config/config.scss";

.root {
  @include md-up {
    margin-top: 3.25rem;
  }
}

.container {
  display: flex;
  flex-direction: column-reverse;
  @include md-up {
    flex-direction: row;
  }
  gap: 1.5rem;
}


.description {
  width: 100%;

  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    color: #5b5a5a;

    strong {
      color: #353131;
    }
  }
}

.details {
  @include md-up {
    width: 32.5rem;
    background-color: #fffcfc;
    padding: 2rem;
    border: 0.0625rem solid #d3d5d7;
    box-shadow: 0 0 1.875rem 0.0625rem rgba(202, 196, 196, 0.2);
    border-radius: 0.625rem;
  }

  .item {
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 1.5rem 0;
    border-bottom: 0.0625rem solid #ebebeb;

    .title {
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-weight: 500;
      color: #353131;

      svg {
        color: #ef4c5e;
      }
    }

    .subtitle {
      font-weight: 400;
      color: #5b5a5a;
      padding-top: 0.5rem;
      padding-left: 2.125rem;
    }
  }
}

.photos {
  margin-top: 1rem;
  margin-bottom: 9.5rem;

  .title {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    color: #353131;
  }

  .body {
    display: grid;
    @include md-up {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    gap: 3.25rem;
    padding-top: 2rem;

    img {
      width: 100%;
      object-fit: cover;
      height: 20.75rem;
      border-radius: 0.625rem;
    }
  }
}

.saveButton {
  margin-top: 0.5rem;
  @include md-up {
    display: none;
  }
}
