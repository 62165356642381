.uploader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &__gallery {
    margin-left: 15px;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 160px;
  }
  &__image {
    position: relative;
    height: 140px;
    margin-left: 15px;
    flex: 0 0 172px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  &__delete {
    position: absolute;
    top: 4px;
    right: 4px;
    padding: 0 4px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
