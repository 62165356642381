.checkbox img {
  width: calc(1em + 6px) !important;
  height: calc(1em + 4px) !important;
  top: calc((0% - (100% - 1em)) - 13%) !important;
  left: -2px !important;
  background: white;
  overflow: hidden;
  border-radius: 5px;
  padding: 3px;
}

.pretty{
  margin: 0px;
}

.pretty .state label{
  margin-left: 8px;
}