.ql-toolbar.ql-snow{
    border-radius: 8px 8px 0px 0px;
    border-color: #EBEBEB;
    background: white;
}

.ql-container{
    border-radius: 0px 0px 8px 8px;
    border-color: #EBEBEB !important;
    background: white;
}