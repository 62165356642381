.card{
    // height: 90px;
    &__md__texts{
        .card__info{
            .card__name{
                font-weight: 500;
                font-size: 14px;
            }
            span{
                font-weight: 400;
                font-size: 12px;
            }
        }
    }
    &__info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 24px;
        padding: 7px 0px 7px 0px;
    }
    &__name{
        color: #353131;
        font-weight: 700;
        font-size: 16px;
    }
    &__profession{
        color: #5B5A5A;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 4px;
    }
    &__id{
        color: #8B8A8A;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 4px;
    }
}

.horizontally{
    display: flex;
    justify-content: flex-start;
}
.vertically{
    display: flex;
    flex-direction: column;
    height: auto;
    .card__info{
        margin: auto;
        text-align: center;
    }
    .avatar{
        margin-left: auto;
        margin-right: auto;
    }
}
